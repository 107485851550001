
function getToken(): string {
    return localStorage.getItem('easy.bi_token');
}
function isAuthenticated(tokens): boolean {
    tokens = tokens || this.getToken();
    if (tokens === null) {
        return false;
    } else {
        return !this.isTokenExpired(tokens);
    }
}
function isTokenExpired(token: string): boolean {
    let date = new Date(+JSON.parse(token).expire * 1000);
    if (Date.now > date.getTime) {
        return false;
    } else {
        return true;
    }
}